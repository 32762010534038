(function () {

  const prevArrow = `
    <button type="button" class="slick-prev">
      <span>prev</span>
    </button>
  `;

  const nextArrow = `
    <button type="button" class="slick-next">
      <span>next</span>
    </button>
  `;

  $('.testimonials__slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 7000,
    nextArrow,
    prevArrow,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          adaptiveHeight: true,
          arrows: false
        }
      }
    ],
  });

  $('.post-slider').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow,
    prevArrow,
    infinite: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }
    ],
  });

  $('.values-list__items')
    .on('destroy', function (ev, slick) {
      let carousel = $(this),
        reinit = debounce(function () {
          if (slick.activeBreakpoint < window.innerWidth) {
            return;
          }
          carousel.slick(slick.options);
          window.removeEventListener('resize', reinit);
        }, 500);

      // Assign our debounced callback to window.resize.
      window.addEventListener('resize', reinit);
    })
    .slick({
      mobileFirst: true,
      slidesToShow: 1,
      dots: true,
      arrows: false,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: 'unslick'
        }
      ]
    });

  $('.slick-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $(this).find('.slick-dots li.slick-active').addClass('before-change')
  });

  $('.slick-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
    $(this).find('.slick-dots li').removeClass('before-change')
  });

  $('.case-study__slider').slick({
    centerMode: true,
    // variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '34px',
    dots: false,
    nextArrow,
    prevArrow,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          variableWidth: true,
        }
      }
    ]
  });

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

})()