(function () {
  if (!!$('.blog').length) {
    let $blogGrid = $('.blog__grid')
    let $posts = $blogGrid.find('.post-card');

    $('.blog__switcher--list').on('click', function () {
      $blogGrid.removeClass('grid');
      $posts.addClass('list-view')

      $(this).addClass('active').siblings().removeClass('active')
    })

    $('.blog__switcher--tile').on('click', function () {
      $blogGrid.addClass('grid');
      $posts.removeClass('list-view')
      $(this).addClass('active').siblings().removeClass('active')
    })
  }

  new NativejsSelect({
    selector: '.custom-select'
  });


})()