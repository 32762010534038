(function() {
  const $header = $('.page-header');
  const $menuClose = $('.page-header__menu-close');
  const $hamburger = $('.page-header__menu-opener');
  let isMenuOpened = false;

  $hamburger.on('click', function() {
    $header.addClass('menu-opened');
  });

  $menuClose.on('click', function() {
    $header.removeClass('menu-opened');
  });

  $('.main-nav a').on('click', function () {
    $header.removeClass('menu-opened');
  })

})();
