$(document).ready(() => {
  // Don't touch
  objectFitImages();
  svg4everybody();
  // checking webP browser support
  require('./modernizr.js');
  //
  // End don't touch

  require('./target-blank.js');
  require('./header');
  require('./slider.js');
  require('./animation');
  require('./parallax');
  require('./menu');
  require('./contact-popup');
  require('./blog');
  require('./loadmore')
  // require('./vimeo')
});

// remove preloader
$(window).on('load', () => {
  $('.preloader').fadeOut();
  $('html').addClass('page-loaded');
  // AOS.init({
  //   once: true,
  //   useClassNames: true,
  //   duration: 500,
  //   delay: 200,
  //   // easing: 'linear',
  //   // easing: 'ease',
  //   // easing: 'ease-in',
  //   // easing: 'ease-out',
  //   // easing: 'ease-in-out',
  //   // easing: 'ease-in-back',
  //   // easing: 'ease-out-back',
  //   // easing: 'ease-in-out-back',
  //   // easing: 'ease-in-sine',
  //   // easing: 'ease-out-sine',
  //   // easing: 'ease-in-out-sine',
  //   // easing: 'ease-in-quad',
  //   // easing: 'ease-out-quad',
  //   // easing: 'ease-in-out-quad',
  //   // easing: 'ease-in-cubic',
  //   // easing: 'ease-out-cubic',
  //   // easing: 'ease-in-out-cubic',
  //   // easing: 'ease-in-quart',
  //   // easing: 'ease-out-quart',
  //   easing: 'ease-in-out-quart',
  //
  // });
});
