(function () {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero.parallax",
      start: "top top",
      end: "bottom top",
      toggleClass: 'fixed',
      scrub: true
    }
  });

  gsap.utils.toArray(".hero.parallax .parallax__layer").forEach(layer => {
    const depth = layer.dataset.depth / 100;
    const movement = -(layer.offsetHeight * depth)
    tl.to(layer, {y: movement, ease: "none"}, 0);
  });

  if (!!$('.parallax_contact').length) {
    let tweenContact = new TimelineMax()
      .add([
        TweenMax.from(".contact .parallax-1", 1, {top: '275px', ease: Linear.easeNone}),
        TweenMax.from(".contact .parallax-2", 1, {top: '380px', ease: Linear.easeNone}),
        TweenMax.from(".contact .parallax-3", 1, {top: '520px', ease: Linear.easeNone}),
        TweenMax.from(".contact .parallax-4", 1, {top: '900px', ease: Linear.easeNone})
      ]);

    ScrollTrigger.create({
      trigger: ".contact",
      start: "top bottom",
      end: "bottom bottom",
      animation: tweenContact,
      scrub: true
    })
  }

})()