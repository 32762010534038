(function() {

    let $name = $("#name")
    let $email = $("#email")
    let $error = $('.form__error')

    $('.contact__button').on('click', function(e) {
        e.preventDefault();
        $('.contact-popup').addClass('isOpen')
    })

    $('.contact-popup__close').on('click', function() {
        closeForm()
    })

    $('.form_contact').on('submit', (event) => send(event, _wpObject.templateUrl + '/send.php'))


    function send(event, php) {
        event.preventDefault ? event.preventDefault() : event.returnValue = false;
        if (validateForm()) {
            console.log("Sending message");
            let req = new XMLHttpRequest();
            req.open('POST', php, true);
            req.onload = function() {
                if (req.status >= 200 && req.status < 400) {
                    let json = JSON.parse(this.response);
                    console.log(json);

                    if (json.result == "success") {
                        alert("Message has been sent");
                        closeForm()
                    } else {
                        alert("Error. Message hasn't been sent");
                    }
                } else { alert("Server error. Number: " + req.status); }
            };

            req.onerror = function() { alert("Request error"); };
            req.send(new FormData(event.target));
        }
    }

    function closeForm() {
        $name.val('');
        $email.val('');
        $error.text('');
        $('.contact-popup').removeClass('isOpen')
    }

    function validateForm() {
        $error.text('')

        let name = $name.val();
        let email = $email.val();

        if (!name.length) {
            $error.text("Please, fill required fields");
            $name.addClass('with-error').focus();
            return false;
        }

        if (!email.length) {
            $error.text("Please, fill required fields");
            $email.addClass('with-error').focus();
            return false;
        }

        if (!isValidName($name.val())) {
            $error.text("Name is incorrect");
            $name.addClass('with-error').focus();
            return false;
        }
        if (!isValidEmail($email.val())) {
            $error.text("Email is incorrect");
            $email.addClass('with-error').focus();
            return false;
        }
        return true;
    }

    function isValidName(name) {
        let pattern = new RegExp(/^[a-zA-Z\.\u0590-\u05FF \s']{1,}$/i);
        return pattern.test(name);
    }

    function isValidEmail(email) {
        let pattern = new RegExp(/.+@.+\..+/i);
        return pattern.test(email);
    }
})()