(function () {
  gsap.registerPlugin(ScrollTrigger)

  gsap.utils.toArray('.animated, .animated-title').forEach(el => {
    ScrollTrigger.create({
      trigger: el,
      start: 'top 80%',
      onEnter: () => $(el).addClass('animate')
    })
  })

  $('.values-list__item').on('mouseenter touchstart', function () {
    if ($(window).width() > 768) {
      let valuesTitle = $('.values-list__title');
      let $this = $(this)
      let top = $this.position().top;
      $this.addClass('active').siblings().removeClass('active');
      valuesTitle.css({top: top})
    }
  })

  // function animateListInf(elem) {
  //   let valuesLength = $(elem).find('li').length;
  //   let duration = 2000
  //   animationList(elem, duration)
  //   setInterval(function () {
  //     animationList(elem, duration)
  //   }, (valuesLength + 1) * (duration + 50))
  // }
  //
  // function animationList(elem, duration) {
  //   let valuesTitle = $(elem).find('.animated-list__title');
  //   let valuesLength = $(elem).find('li').length;
  //   let items = $(elem).find('li')
  //   let lastTop;
  //   items.each(function (i) {
  //     let item = $(this);
  //     if (i == valuesLength - 1) {
  //       lastTop = item.position().top
  //     }
  //     setTimeout(function () {
  //       item.addClass('active').siblings().removeClass('active');
  //       valuesTitle.css({top: (item.position().top), opacity: 1})
  //     }, 2000 * i);
  //   })
  //   setTimeout(function () {
  //     valuesTitle.css({top: lastTop + 200})
  //     $('.values-list__item').removeClass('active')
  //   }, valuesLength * duration)
  //   setTimeout(function () {
  //     valuesTitle.css({top: -100, opacity: 0})
  //   }, (valuesLength + 1) * duration)
  // }


  if (!!$('.case-study').length) {
    animateCaseStudyPage()
  }

  if (!!$('.case-study-card').length) {
    animateCaseStudyCard()
  }

  if (!!$('.hero--white').length) {
    animateHeroPatten()
  }

  if (!!$('.post__content').length) {
    animatePost()
  }

  imageParallax()

  function animatePost() {
    $('.post__content h2').addClass('animated-title animated--left');
    gsap.utils.toArray('.post__content h2, .post__signature, .sidebar__title, .sidebar').forEach((item) => {
      ScrollTrigger.create({
        trigger: item,
        start: "top 80%",
        toggleClass: "animate"
      })
    })
    let tl1 = gsap.timeline()
    tl1.to('.post__hero', {yPercent: 30, ease: 'none'})
    ScrollTrigger.create({
      trigger: '.post__description',
      start: 'bottom 50%',
      scrub: true,
      animation: tl1
    })
  }

  function imageParallax() {
    gsap.utils.toArray('.img-parallax').forEach(image => {
      let $img = $(image).find('img')
      let tl = gsap.timeline();
      tl
        .from($img, {yPercent: -10, ease: "none"})
        .to($img, {yPercent: 10, ease: "none"})
      ScrollTrigger.create({
        trigger: image,
        start: "top 80%",
        animation: tl,
        scrub: true
      })
    })
  }

  function animateHeroPatten() {
    let $pattern = $('.hero--white .hero__pattern')
    let tl = gsap.timeline();
    tl.to($pattern, {opacity: 0, ease: "none"})
    ScrollTrigger.create({
      trigger: $('.hero--white'),
      start: "top top",
      animation: tl,
      scrub: true
    })
  }

  function animateCaseStudyCard() {
    gsap.utils.toArray('.case-study-card:not(.animated)').forEach((card, index) => {
      let $lineLong = $(card).find('.line--long');
      let $lineShort = $(card).find('.line--short');
      let width = 0;
      if ($(window).width() < 1680) {
        width = $(card).find('.case-study-card__content').outerWidth();
      } else {
        width = ($(window).width() - 1680) / 2 + $(card).find('.case-study-card__content').outerWidth();
      }
      let tween = gsap.timeline();

      tween
        .to($lineLong, {width: width, duration: 0.5})
        .to($lineShort, {width: width * 0.45, duration: 0.5, delay: 0.1})

      ScrollTrigger.create({
        trigger: card,
        start: "top 80%",
        animation: tween,
        toggleClass: 'animated'
      })
    })
  }

  function animateCaseStudyPage() {

    let tl1 = gsap.timeline()
    tl1.to('.case-study__hero', {yPercent: 60, ease: 'none'})
    ScrollTrigger.create({
      trigger: '.case-study__hero',
      start: 'bottom 50%',
      scrub: true,
      animation: tl1
    })

    let tl2 = gsap.timeline();
    tl2
      .from('.case-study__image', {marginBottom: $(window).width() > 768 ? -120 : -70, ease: "none"})
      .to('.case-study__image', {marginBottom: $(window).width() > 768 ? -250 : -170, ease: "none"})
    ScrollTrigger.create({
      trigger: '.case-study__section--image',
      start: 'top 80%',
      scrub: true,
      animation: tl2
    })

    gsap.utils.toArray('.section-parallax--top').forEach(parallax => {
      let tl = gsap.timeline()
      tl.from(parallax, {y: 100, ease: 'none'})
      ScrollTrigger.create({
        trigger: parallax,
        start: 'top bottom',
        end: "top 60%",
        scrub: true,
        animation: tl
      })
    })
  }

})()