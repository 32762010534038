(function () {
  let btn = document.getElementById('loadmore');
  if (btn) {
    btn.addEventListener('click', loadmore);

    function loadmore(e) {

      e.preventDefault();

      let currentPage = e.target.dataset.page;
      let type = e.target.dataset.type;
      let maxPages = e.target.dataset.max;
      let postsPerPage = e.target.dataset.posts;
      let category = 'all'
      if (!!$('#blogFilter').length) {
        category = $(`#blogFilter option:selected`).val();
      }
      $.ajax({
        url: window.wp_data.ajax_url,
        type: 'POST',
        data: {
          page: currentPage,
          action: 'get_projects',
          post_type: type,
          posts_per_page: postsPerPage,
          filter: category
        },
        beforeSend: function (data) {
          btn.setAttribute('disable', true);
          btn.innerText = "Loading"
        },
        success: function (data) {
          let resp = JSON.parse(data);
          console.log(resp)
          let list = document.getElementById('loadmore-wrapper');
          currentPage++
          btn.dataset.page = currentPage;
          list.innerHTML += resp.content;
          (currentPage == maxPages) ? btn.style.display = "none" : btn.innerText = "Load more";
          if (type == "case_study") {
            gsap.utils.toArray('.case-study-card:not(.animated)').forEach((card, index) => {
              let $lineLong = $(card).find('.line--long');
              let $lineShort = $(card).find('.line--short');
              let width = 0;
              if ($(window).width() < 1680) {
                width = $(card).find('.case-study-card__content').outerWidth();
              } else {
                width = ($(window).width() - 1680) / 2 + $(card).find('.case-study-card__content').outerWidth();
              }
              let tween = gsap.timeline();

              tween
                .to($lineLong, {width: width, duration: 0.5})
                .to($lineShort, {width: width * 0.45, duration: 0.5, delay: 0.1})

              ScrollTrigger.create({
                trigger: card,
                start: "top 80%",
                animation: tween,
                toggleClass: 'animated'
              })
            })
          } else {
            document.querySelectorAll('.post-card').forEach(item => {
              if (!item.classList.contains('animate')) item.classList.add('animate')
            })
          }
        },
        complete: function (data) {
          btn.setAttribute('disable', false);
          btn.innerText = "Load more"
        }
      });
    }
  }
})()